import * as React from "react";
import {
  useNotify,
  useRefresh,
  useRedirect,
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  EmailField,
  BooleanField,
} from "react-admin";

const UserShow = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onError = (error) => {
    notify(`Could not load post: ${error.message}`, { type: "warning" });
    redirect("/users");
    refresh();
  };

  return (
    <Show {...props} onError={onError}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="username" />
        <TextField source="first_name" />
        <TextField source="last_name" />
        <TextField source="name" />
        <EmailField source="email" />
        <TextField label="UserId" source="user_id" />
        <TextField label="StripeId" source="stripe_id" />
        <BooleanField source="is_active" />
        <BooleanField source="is_superuser" />
        <DateField source="date_joined" showTime />
      </SimpleShowLayout>
    </Show>
  );
};
export default UserShow;
