import * as React from 'react';
import {
  List,
  Datagrid,
  ShowButton,
  DateField,
  TextField,
  EmailField,
  BooleanField,
  TextInput,
} from 'react-admin';

const userFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
];

const UserList = () => (
  <List filters={userFilters}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="username" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="name" />
      <EmailField source="email" />
      <TextField label="UserId" source="user_id" />
      <TextField label="StripeId" source="stripe_id" />
      <BooleanField source="is_active" />
      <BooleanField source="is_superuser" />
      <DateField source="date_joined" showTime />
    </Datagrid>
  </List>
);

export default UserList;
