import * as React from 'react';
import { TopToolbar, ShowButton, ListButton } from 'react-admin';

const TemmplateEditActions = () => (
  <TopToolbar>
    <ShowButton />
    {/* Add your custom actions */}
    <ListButton />
  </TopToolbar>
);
export default TemmplateEditActions;
