import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
  // ReferenceInput,
  // SelectInput,
  NumberInput,
  useNotify,
  useRedirect,
} from 'react-admin';
import TemplateCreateActions from './TemplateCreateActions';

const TemplateCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (data) => {
    notify(`Changes saved`);
    redirect(`/templates/${data.id}`);
  };

  const props = {
    actions: <TemplateCreateActions />,
    mutationOptions: onSuccess,
  };
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="title" validate={[required()]} fullWidth />

        <NumberInput
          source="project_id"
          label="Project ID"
          validate={[required()]}
          fullWidth
        />

        {/* <ReferenceInput
          label="Project"
          validate={[required()]}
          source="project_id"
          reference="projects"
          fullWidth
        >
          <SelectInput optionText="name" />
        </ReferenceInput> */}

        <BooleanInput label="Is Active" source="is_active" />

        <TextInput lavel="Thumbnail Image" source="image_thumbnail" fullWidth />
        <TextInput lavel="Image Preview 1" source="image_preview_1" fullWidth />
        <TextInput lavel="Image Preview 2" source="image_preview_2" fullWidth />

        <TextInput
          label="Short Description"
          source="short_description"
          multiline
          fullWidth
        />

        <TextInput
          label="Long Description"
          source="long_description"
          multiline
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};
export default TemplateCreate;
