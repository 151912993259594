import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
  // ReferenceInput,
  // SelectInput,
  NumberInput,
} from 'react-admin';
import TemplateEditActions from './TemplateEditActions';

const TemplateEdit = () => {
  const props = {
    actions: <TemplateEditActions />,
    mutationMode: 'pessimistic',
  };
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput disabled source="id" label="ID" fullWidth />
        <TextInput
          disabled
          source="template_id"
          label="Template ID"
          fullWidth
        />

        <TextInput source="title" validate={[required()]} fullWidth />

        <NumberInput source="project_id" label="Project ID" fullWidth />

        {/* <ReferenceInput
          label="Project"
          validate={[required()]}
          source="project_id"
          reference="projects"
          fullWidth
        >
          <SelectInput optionText="name" />
        </ReferenceInput> */}

        <BooleanInput label="Is Active" source="is_active" />

        <TextInput lavel="Thumbnail Image" source="image_thumbnail" fullWidth />
        <TextInput lavel="Image Preview 1" source="image_preview_1" fullWidth />
        <TextInput lavel="Image Preview 2" source="image_preview_2" fullWidth />

        <TextInput
          label="Short Description"
          source="short_description"
          multiline
          fullWidth
        />

        <TextInput
          label="Long Description"
          source="long_description"
          multiline
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};
export default TemplateEdit;
