import * as React from "react";
import { List, Datagrid, TextField } from "react-admin";

const WorkspaceList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField label="WorkspaceId" source="workspace_id" />
    </Datagrid>
  </List>
);

export default WorkspaceList;
