import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  ShowButton,
  EditButton,
  // DeleteButton,
  ReferenceField,
  DeleteWithConfirmButton,
} from 'react-admin';
import TemplateActions from './TemplateListActions.js';

const WorkspaceList = () => (
  <List actions={<TemplateActions />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField label="Title" source="title" />
      <ReferenceField
        label="Project Name"
        source="project_id"
        reference="projects"
      >
        <TextField source="name" />
      </ReferenceField>

      <BooleanField source="is_active" />
      <EditButton />
      <ShowButton />
      {/* <DeleteButton /> */}
      <DeleteWithConfirmButton
        confirmContent="You will not be able to recover this record. Are you sure?"
        // translateOptions={{ name: record.name }}
      />
    </Datagrid>
  </List>
);

export default WorkspaceList;
