import { combineDataProviders, fetchUtils } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  options.headers.set('Content-Type', 'application/json');
  return fetchUtils.fetchJson(url, options);
};

const dataProvider1 = simpleRestProvider(
  process.env.REACT_APP_API_ENDPOINT || 'http://localhost:30000/api',
  httpClient
);

const dataProvider = combineDataProviders((resource) => {
  switch (resource) {
    case 'projects':
    case 'tasks':
    case 'templates':
    case 'users':
    case 'workspaces':
      return dataProvider1;
    default:
      throw new Error(`Unknown resource: ${resource}`);
  }
});

export default dataProvider;
