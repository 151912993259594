import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  ReferenceField,
} from "react-admin";

const WorkspaceList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField label="Project Id" source="project_id" />
      <BooleanField source="archived" />
      <BooleanField source="public" />
      <TextField source="color" />
      <ReferenceField label="Owner" source="owner_id" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="workspace"
        source="workspace_id"
        reference="workspaces"
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default WorkspaceList;
