import {
  TopToolbar,
  // FilterButton,
  CreateButton,
  ExportButton,
} from 'react-admin';

const TemplateListActions = () => (
  <TopToolbar>
    {/* <FilterButton /> */}
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export default TemplateListActions;
