import * as React from 'react';
import { Admin, Resource } from 'react-admin';

import AuthProvider from './authProvider';
import dataProvider from './dataProvider';

import Dashboard from './pages/Dashboard';

import projects from './resources/projects';
// import tasks from './resources/task';
import templates from './resources/templates';
import users from './resources/users';
import workspaces from './resources/workspaces';

const App = () => (
  <Admin
    dashboard={Dashboard}
    authProvider={AuthProvider}
    dataProvider={dataProvider}
  >
    <Resource name="projects" {...projects} />
    {/* <Resource name="tasks" {...tasks} /> */}
    <Resource name="templates" {...templates} />
    <Resource name="users" {...users} />
    <Resource name="workspaces" {...workspaces} />
  </Admin>
);

export default App;
