import TemplateCreate from './TemplateCreate';
import TemplateEdit from './TemplateEdit';
import TemplateList from './TemplateList';

const index = {
  list: TemplateList,
  create: TemplateCreate,
  edit: TemplateEdit,
};

export default index;
